import * as React from "react";
import "@fontsource/dm-sans";
import "./payment-error.sass";
import Layout from "../components/Layout";

// markup
const PaymentError = () => {
  React.useEffect(() => {
    window.parent.postMessage("payment-error");
  })
  return (
    <Layout>
      <div id="payment-error-prompt">
        <h3>Erreur de paiement</h3>
        <p>
          Une erreur est survenue lors de votre paiement. <br />
          Merci de réessayer ou nous contacter.
        </p>
      </div>
    </Layout>
  );
};

export default PaymentError;
